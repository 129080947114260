import React, { useEffect, useMemo, useState } from "react";
import back from "../../../assets/images/back.svg";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import shallow from "zustand/shallow";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Link,
  withRouter,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";
import { useTimeCardStore, useAuthStore, useServiceStore } from "../../../store";
import { get } from "../../../types/api";
import Loader from "../../shared/Loader/Loader";
import moment from "moment";
import { getProjectAndForemen, updateUserSettings } from "../Helpers";


interface IServiceTicketDetailsProps extends RouteComponentProps {
  breadcrumb: any;
  title: any;
  showStatusSection?: any;
  showSelectSection?: any;
  areSideFiltersActive?: boolean;
  selectedTimecardDetails?: {
    projectId: number;
    supervisorId: number;
    shiftId: string;
  };
}

const Header = (props: IServiceTicketDetailsProps) => {
  // const breadcrumbText = [];
  const history = useHistory();
  const { setStatusFilter, setFilterObj } = useTimeCardStore.getState();
  const { statusFilter } = useTimeCardStore(
    (state) => ({ statusFilter: state.statusFilter }),
    shallow
  );
  const [filter, setFilter] = useState("ALL");
  const [loading, setLoading] = useState(false);
  const [projectSelectedValues, setProjectSelectedValues] = useState({
    project: [] as any,
    supervisor: [] as any,
    shift: [] as any,
  });
  const { selectedProjectsSupervisorsShift, projectsAndSupervisors, timeCardDate } =
    useTimeCardStore(
      (state) => ({
        selectedProjectsSupervisorsShift:
        state.selectedProjectsSupervisorsShift,
        projectsAndSupervisors: {
          ...state.projectsAndSupervisors,
          supervisors: props.title === 'Calendar Dashboard' ? [{fullName: 'All Foreman', id: -1}, ...state.projectsAndSupervisors.supervisors]: state.projectsAndSupervisors.supervisors,
        },
        // projectsAndSupervisors: state.projectsAndSupervisors,
        timeCardDate: state.timeCardDate
      }),
      shallow
    );
  const { setProjectsAndSupervisors } = useTimeCardStore.getState();
  
  const { setSelectedProjectsSupervisorsShift } = useTimeCardStore.getState();
  const { companyId } = useServiceStore(serviceStore => ({companyId: serviceStore.companyId}));
  const { userSettings } = useAuthStore(
    (state) => ({ userSettings: state.userSettings }),
    shallow
  );

  const shifts = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
  ];

  const [updateCardModal, setDeleteCardModal] = useState(false);
  const toggleUpdateCard = () => setDeleteCardModal(!updateCardModal);
  const [componentLoader, setComponentLoader] = useState(false);

  const pathname = useMemo(() => {
    return history.location.pathname.split("/")[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userSettings &&
      userSettings.selectedProjectId &&
      (!selectedProjectsSupervisorsShift.project.length || 
        (selectedProjectsSupervisorsShift.project[0].id !== userSettings.selectedProjectId))
    ) {
      // calling project and foreman list only when the data is not there in the global state
      // called on timecard page
      getProjectAndForemenAndSetValues(userSettings.selectedProjectId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings, userSettings.selectedProjectId]);

  useEffect(() => {
    if (
      pathname === "timecardList" &&
      props.selectedTimecardDetails?.projectId
    ) {
      // called on timecardList page
      getProjectAndForemenAndSetValues(userSettings.selectedProjectId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTimecardDetails?.projectId]);

  useEffect(() => {
    setFilter(statusFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  useEffect(() => {
    setProjectSelectedValues({ ...selectedProjectsSupervisorsShift });
  }, [selectedProjectsSupervisorsShift]);

  useEffect(() => {
    if (props.areSideFiltersActive) {
      setProjectSelectedValues({
        project: [],
        supervisor: [],
        shift: [],
      });
    }
  }, [props.areSideFiltersActive]);

  const getProjectAndForemenAndSetValues = async (
    projectId: any,
    isComponentMounted = false
  ) => {
    if (projectId) {
      setLoading(true);
      let res = await getProjectAndForemen(projectId);
      if (res) {
        let result = '';
        if(res.masterProjects) {
          result = res.masterProjects.find(
            (e: any) => e.id === Number(projectId)
          );
          // if select project not found, update project
          if(!result) {
            await updateUserSettings(companyId, res.masterProjects[0].id);
            // get updated project foreman
            res = await getProjectAndForemen(projectId)
            result = res.masterProjects[0];
          }
        }
        const supervisor =
          props.selectedTimecardDetails?.supervisorId &&
          res.supervisors.find(
            (eachSupervisor: { supervisorEmployeeId: any }) =>
              eachSupervisor.supervisorEmployeeId ===
              props.selectedTimecardDetails?.supervisorId
          );
        const shift =
          props.selectedTimecardDetails?.shiftId &&
          shifts.find(
            (eachShift) =>
              eachShift.label === props.selectedTimecardDetails?.shiftId
          );
        const obj = {
          project: result ? [result] : [],
          supervisor: supervisor
            ? [supervisor]
            : (res.supervisors
              && res.supervisors.length
              ? [res.supervisors[0]] : []),
          shift: shift ? [shift] : [shifts[0]],
        };
        setProjectSelectedValues(obj);
        if (isComponentMounted) {
          setSelectedProjectsSupervisorsShift(obj);
        }
      }
      setLoading(false);
    }
  };

  const getSelectedDateOtherTimecard = async () => {
    if (selectedProjectsSupervisorsShift?.project[0]?.id) {
      const url = `/v1/api/time-card/filter?projectId=${
        projectSelectedValues?.project[0]?.id
      }&supervisorId=${
        projectSelectedValues?.supervisor[0]?.id
      }&fromDate=${moment(timeCardDate.toString())
        .format("YYYYMMDD")}&toDate=${moment(timeCardDate.toString()).format(
        "YYYYMMDD"
      )}&orgEntityId=${companyId}`;
      const { data } = await get(url);
      if (data.data && data.data.length) {
        history.push(`/timecardList/${data.data[0].id}`)
      } else {
        history.push('/timecardList')
      }
    }
  };

  const handleInputChange = async (e: any) => {
    if (!e || e.trim() === "") {
      setProjectsAndSupervisors({...projectsAndSupervisors, masterProjects: projectsAndSupervisors.originalMasterProjects });
      return;
    } else {
      setComponentLoader(true);
      let apiURL = `/v1/api/time-card/helper/timecard-projects?orgEntityId=${companyId}&searchText=${e}`;

      const res = await get(apiURL);

      if (res.data && res.data.data) {
        const projectsUpdated = res.data.data.map((e:any) => {
          e['projectDisplayNameUI'] = e.projectDisplayNumber + ": " + e.projectName
          return e;
        });
        const masterProjects = projectsUpdated.filter((e : any) => e._hasSupervisor === 1 && e.id === e.masterProjectId) || [];

        setProjectsAndSupervisors({...projectsAndSupervisors, masterProjects});
        setComponentLoader(false);
      }
    }
  };

  return (
    <div className="header-wrapper">
      <div className="header-left">
        <div className="header-back-wrapper">
          <button
            className="header-back"
            onClick={() => {
              if (pathname === "timecardList") {
                history.push(`/timecard`);
              } else if (pathname === "timecard") {
                history.push(`/`);
              } else {
                history.goBack();
              }
            }}
          >
            <img src={back} alt="back" />
          </button>
        </div>
        <div className="header-breadcrumb-wrapper">
          <div className="breadcrumb-content">
            <Link to="/" className="breadcrumb-text">
              Home
            </Link>
            <Link to="/" className="breadcrumb-text">
              {props.breadcrumb}
            </Link>
          </div>
          <div className="header-content">
            <h1 className="page-title">{props.title}</h1>
          </div>
        </div>
        {props.showSelectSection && (
          <div className="project-details" onClick={toggleUpdateCard}>
            <span className="text-label-value">
              <small className="text-label">Project</small>
              <small className="text-value">
                {selectedProjectsSupervisorsShift.project.length &&
                selectedProjectsSupervisorsShift.project[0] &&
                selectedProjectsSupervisorsShift.project[0].projectName &&
                !props.areSideFiltersActive
                  ? (selectedProjectsSupervisorsShift.project[0].projectDisplayNumber + ": " + selectedProjectsSupervisorsShift.project[0].projectName)
                  : ""}
              </small>
            </span>
            <span className="text-label-value">
              <small className="text-label">Foreman</small>
              <small className="text-value">
                {(selectedProjectsSupervisorsShift.supervisor.length &&
                selectedProjectsSupervisorsShift.supervisor[0] &&
                selectedProjectsSupervisorsShift.supervisor[0].fullName &&
                !props.areSideFiltersActive)
                  ? selectedProjectsSupervisorsShift.supervisor[0].fullName
                  : ""}
              </small>
            </span>
            <span className="text-label-value">
              <small className="text-label">Shift</small>
              <small className="text-value">
                {selectedProjectsSupervisorsShift.shift.length &&
                 selectedProjectsSupervisorsShift.shift[0] &&
                 selectedProjectsSupervisorsShift.shift[0].value &&
                !props.areSideFiltersActive
                  ? selectedProjectsSupervisorsShift.shift[0].value
                  : ""}
              </small>
            </span>
          </div>
        )}
      </div>
      {props.showStatusSection && (
        <div className="header-center">
          <ul className="timecard-legends">
            <li
              className={`timecard-legend ${
                filter === "ALL" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("ALL");
                  setFilter("ALL");
                }}
                className="legend-item item-all"
              >
                <span className="legend-name">All</span>
              </button>
            </li>
            <li
              className={`timecard-legend ${
                filter === "OPEN" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("OPEN");
                  setFilter("OPEN");
                }}
                className="legend-item item-open"
              >
                <span className="legend-name">Open</span>
              </button>
            </li>
            <li
              className={`timecard-legend ${
                filter === "SUBMITTED" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("SUBMITTED");
                  setFilter("SUBMITTED");
                }}
                className="legend-item item-submitted"
              >
                <span className="legend-name">Submitted</span>
              </button>
            </li>
            <li
              className={`timecard-legend ${
                filter === "REJECTED" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("REJECTED");
                  setFilter("REJECTED");
                }}
                className="legend-item item-rejected"
              >
                <span className="legend-name">Rejected</span>
              </button>
            </li>
            <li
              className={`timecard-legend ${
                filter === "APPROVED" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("APPROVED");
                  setFilter("APPROVED");
                }}
                className="legend-item item-approved"
              >
                <span className="legend-name">Approved</span>
              </button>
            </li>
            <li
              className={`timecard-legend ${
                filter === "EXPORTED" ? "legend-active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setStatusFilter("EXPORTED");
                  setFilter("EXPORTED");
                }}
                className="legend-item item-exported"
              >
                <span className="legend-name">Exported</span>
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* Update Card Popup */}
      <Modal
        isOpen={updateCardModal}
        fade={false}
        toggle={() => {
          toggleUpdateCard();
          setSelectedProjectsSupervisorsShift(selectedProjectsSupervisorsShift);
        }}
        className="update-timecard-modal modal-md"
      >
        {loading && <Loader></Loader>}
        <ModalHeader
          toggle={() => {
            toggleUpdateCard();
            setSelectedProjectsSupervisorsShift(
              selectedProjectsSupervisorsShift
            );
          }}
        >
          Choose the Project, Foremen & Shift
        </ModalHeader>
        <ModalBody>
          <form className="update-timecard-form">
            <div className="form-group">
              {componentLoader && (
                <Loader showComponentLoader={true} />
              )} 
              <SelectDropdown
                labelKey="projectDisplayNameUI"
                onInputChange={handleInputChange}
                onChange={(e: any) => {
                  if (e?.length && e[0]?.id) {
                    const selected = e[0];
                    const existsInOriginal = projectsAndSupervisors.originalMasterProjects.find(
                      (project: any) => project.id === selected.id
                    );
              
                    if (!existsInOriginal) {
                      setProjectsAndSupervisors({...projectsAndSupervisors, 
                        masterProjects: [selected, ...projectsAndSupervisors.originalMasterProjects], 
                        originalMasterProjects: [selected, ...projectsAndSupervisors.originalMasterProjects]
                      });
                    } else {
                      setProjectsAndSupervisors({...projectsAndSupervisors, 
                        masterProjects: projectsAndSupervisors.originalMasterProjects, 
                        originalMasterProjects: projectsAndSupervisors.originalMasterProjects
                      });
                    }
                  }

                  setProjectSelectedValues({
                    project: e,
                    supervisor: [],
                    shift: [],
                  });
                  if (e && e.length) {
                    getProjectAndForemenAndSetValues(e[0].id);
                  }
                }}
                selected={projectSelectedValues.project || []}
                options={projectsAndSupervisors.masterProjects || []}
                label={"Project"}
              />
            </div>
            <div className="form-group">
              <SelectDropdown
                labelKey="fullName"
                onChange={(e: any) =>
                  setProjectSelectedValues({
                    ...projectSelectedValues,
                    supervisor: e,
                  })
                }
                selected={projectSelectedValues.supervisor || []}
                options={projectsAndSupervisors.supervisors || []}
                label={"Foremen"}
              />
            </div>
            <div className="form-group">
              <SelectDropdown
                labelKey="label"
                onChange={(e: any) =>
                  setProjectSelectedValues({
                    ...projectSelectedValues,
                    shift: e,
                  })
                }
                selected={projectSelectedValues.shift || []}
                options={shifts || []}
                label={"Shift"}
              />
            </div>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  toggleUpdateCard();
                  setSelectedProjectsSupervisorsShift(
                    selectedProjectsSupervisorsShift
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  setFilterObj({});
                  toggleUpdateCard();
                  setSelectedProjectsSupervisorsShift(projectSelectedValues);
                  updateUserSettings(0, projectSelectedValues.project[0].id);
                  if (pathname === "timecardList") {
                    getSelectedDateOtherTimecard();
                  }
                }}
              >
                Update
              </Button>{" "}
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(Header);
